import React from 'react';
import InputBox from './input-box';
import { OptionType } from './select';

interface AutoCompleteProps {
    value?: string;
    defaultValue?: string;
    onChange?: (e: { target: { name?: string, value: string } }) => void;
    required?: boolean;
    name?: string;
    label?: string;
    className?: string;
    wrapperClass?: string;
    load?: () => Promise<Array<string>>;
    options?: Array<string>;
    children?: JSX.Element;
    comment?: string;
    multiple?: boolean;
    disabled?: boolean;
}

export default React.forwardRef<HTMLInputElement, AutoCompleteProps>(function AutoComplete(props, ref) {
    const [value, setValue] = React.useState<string | undefined>(typeof props.value === 'undefined' || props.value === null ? props.defaultValue : props.value);
    const [options, setOptions] = React.useState<Array<string>>([]);
    const filtered = React.useMemo<Array<string>>(() => {
        let result = [];
        if (value) {
            let reg = new RegExp(value, 'i');
            for (let option of options) {
                if (option.toLowerCase().includes(value.toLowerCase())) {
                    result.push(option);
                }
            }
        }
        return result;
    }, [value, options]);
    const [focus, setFocus] = React.useState(false);
    const debounce = React.useRef(false);
    const onChange = React.useCallback((e: { target: { value: string, name?: string } }) => {
        props.onChange ? props.onChange(e) : setValue(e.target.value);
    }, [setValue, props.onChange]);
    const onClick = React.useCallback((index: number) => {
        onChange({ target: { value: filtered[index], name: props.name } });
        setFocus(false);
    }, [onChange, filtered, props.name]);
    const onBlur = React.useCallback(() => {
        window.setTimeout(() => {
            setFocus(false);
        }, 100);
    }, [setFocus]);
    React.useEffect(() => {
        if (props.load) {
            if (debounce.current) {
                return;
            }
            debounce.current = true;
            props.load().then(options => {
                setOptions(options);
                debounce.current = false;
            });
        }
    }, [props.load]);
    React.useEffect(() => {
        setOptions(props.options || []);
    }, [props.options]);
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    const tmp: any = {};
    for (let i of ['defaultValue', 'required', 'name', 'disabled']) {
        if (typeof props[(i as 'defaultValue' | 'required' | 'name' | 'disabled')] !== 'undefined') {
            tmp[i] = props[(i as 'defaultValue' | 'required' | 'name' | 'disabled')];
        }
    }
    return (
        <InputBox label={props.label} required={props.required || false} className={props.wrapperClass} comment={props.comment}>
            <>
                <input
                    className={props.className ? "form-control active " + props.className : "form-control active"}
                    {...tmp}
                    value={value}
                    onChange={onChange}
                    onFocus={setFocus.bind(null, true)}
                    onBlur={onBlur}
                    ref={ref}
                />
                <ul className="auto-complete" hidden={!focus}>
                    {filtered.map((option, index) => (
                        <li key={tmp.name + '-' + option + '-' + index}
                            onClick={onClick.bind(null, index)}
                        >{option}</li>
                    ))}
                </ul>
            </>
        </InputBox >
    );
});